import { HEADER_LANGS } from 'constants/Languages'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import layoutContext from '../layoutContext'
import HeaderDropdown from './HeaderDropdown'

const SelectLanguage = props => {
  const {
    id,
    languages,
    menuOpen,
    isMenuOpen,
    isLangOpen,
    toggleLang,
    isMobile
  } = props

  const regExpLang = languages
    .reduce((acc, curr) => (acc += '|' + curr), '')
    .slice(1)
  const pathre = `^/(${regExpLang})/?(.*)$`

  const { pathname, cult } = useContext(layoutContext)

  const setLangHandler = language => {
    const [, lang = language, path] = pathname.match(pathre) || []
    if (language === lang) {
      return pathname
    }
    return `${language}/${path ? path : ''}`
  }

  const languageLinks = languages.map(language => (
    <Link
      key={language}
      className='header-links-options-item'
      onClick={isMobile && isMenuOpen ? menuOpen : null}
      to={setLangHandler(language)}
    >
      {HEADER_LANGS[language]}
    </Link>
  ))

  return (
    <div className='language-selector'>
      {isMobile && (
        <HeaderDropdown
          id={id}
          title={HEADER_LANGS[cult]}
          links={languageLinks}
          isDropdownOpen={isLangOpen}
          toggleDropdown={toggleLang}
        />
      )}

      {!isMobile && (
        <>
          <div className='language-select-item'>{HEADER_LANGS[cult]}</div>
          <div className='language-list'>{languageLinks}</div>
        </>
      )}
    </div>
  )
}

SelectLanguage.propTypes = {
  id: PropTypes.any,
  languages: PropTypes.array,
  isMenuOpen: PropTypes.bool,
  menuOpen: PropTypes.func,
  isLangOpen: PropTypes.bool,
  toggleLang: PropTypes.func,
  isMobile: PropTypes.bool
}

export default SelectLanguage
