import PropTypes from 'prop-types'
import React from 'react'

import './Burger.scss'

const Burger = ({ menuOpen }) => (
  <div className='nav-btn' onClick={menuOpen}>
    <span />
  </div>
)

Burger.propTypes = {
  menuOpen: PropTypes.func
}

export default Burger
