import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import CloseIcon from './CloseIcon'
import HeaderLinks from './HeaderLinks'
import SelectLanguage from './SelectLanguage'

const MobileMenu = ({ isMenuOpen, menuOpen, primaryLinks, languages }) => {
  const [headerLinks, changeHeaderLinks] = useState([])
  const [isLangOpen, toggleLang] = useState(false)
  const langID = 'lang'

  useEffect(() => {
    toggleLang(false)
    changeHeaderLinks(primaryLinks)
  }, [isMenuOpen])

  function toggleOptionsDropdown(currentID) {
    if (currentID === langID) {
      return toggleLang(!isLangOpen)
    }

    const idx = headerLinks.findIndex(({ id }) => id === currentID)
    const currentItem = headerLinks[idx]

    const newItems = [
      ...headerLinks.slice(0, idx),
      {
        ...currentItem,
        isDropdownOpen: !currentItem.isDropdownOpen
      },
      ...headerLinks.slice(idx + 1)
    ]

    changeHeaderLinks(newItems)
  }

  return (
    <nav className={`mobile-menu ${isMenuOpen && 'open'}`}>
      <CloseIcon isMenuOpen={isMenuOpen} menuOpen={menuOpen} />

      <HeaderLinks
        isMenuOpen={isMenuOpen}
        menuOpen={menuOpen}
        links={headerLinks}
        toggleDropdown={id => toggleOptionsDropdown(id)}
        isMobile
      />

      <SelectLanguage
        id={langID}
        isMenuOpen={isMenuOpen}
        menuOpen={menuOpen}
        languages={languages}
        isLangOpen={isLangOpen}
        toggleLang={id => toggleOptionsDropdown(id)}
        isMobile
      />
    </nav>
  )
}

MobileMenu.propTypes = {
  isMenuOpen: PropTypes.bool,
  menuOpen: PropTypes.func,
  primaryLinks: PropTypes.array,
  languages: PropTypes.array
}

export default MobileMenu
