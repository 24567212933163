export default function resolvePath(...paths) {
  const path = paths
    .filter(p => p.length > 0 && p !== '/')
    .map(p => '/' + p.replace(/^\/|\/$/g, ''))
    .join('')
  if (path.length === 0) {
    return '/'
  }
  return path
}
