import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import store from 'store'
import { LANGUAGES } from 'constants/Languages'

import Footer from './Footer/Footer'
import Header from './Header/header'
import './layout.scss'
import layoutCtx from './layoutContext'

const Layout = ({ children, location: { pathname }, pageContext }) => {
  const [cult, setCult] = useState(LANGUAGES[0])
  const langPrefix = cult
  const contextValue = { cult, setCult, pathname, pageContext, langPrefix }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          pages: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  title
                  path
                  tags
                  lang
                }
              }
            }
          }
        }
      `}
      render={() => (
        <Provider store={store}>
          <layoutCtx.Provider value={contextValue}>
            <Header cult={cult} />
            {children}
            <Footer cult={cult} />
          </layoutCtx.Provider>
        </Provider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object
}

export default hot(Layout)
