import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const FooterMenu = ({ links, title, cult }) => {
  return (
    <div className='footer-menu'>
      <h4 className='footer-menu-title'>{title}</h4>
      <ul className={'open-footer'}>
        {links.map(({ title, path }) => (
          <li className='footer-menu-item' key={title}>
            <Link className='footer-menu-link' to={`${cult}${path}`}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default FooterMenu

FooterMenu.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string,
  cult: PropTypes.string
}
