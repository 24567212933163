import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import { resolvePath } from '../../utils'
import layoutContext from '../layoutContext'
import HeaderDropdown from './HeaderDropdown'

import './HeaderLinks.scss'

const HeaderLinks = ({
  links,
  menuOpen,
  isMenuOpen,
  isMobile,
  toggleDropdown
}) => {
  const { langPrefix } = useContext(layoutContext)

  const getDropdownLinks = options =>
    options.map(({ title, path }) => (
      <Link
        key={title}
        className='header-links-options-item'
        onClick={isMobile && isMenuOpen ? menuOpen : null}
        to={resolvePath(langPrefix, path)}
      >
        {title}
      </Link>
    ))

  const headerLinks = links.map(
    ({ id, title, path, tabIndex, dropDownOptions, isDropdownOpen }) => (
      <li className='header-links-list-item' key={id}>
        {!dropDownOptions ? (
          <Link
            className='header-links'
            onClick={isMobile && isMenuOpen ? menuOpen : null}
            to={resolvePath(langPrefix, path)}
            tabIndex={tabIndex}
          >
            {title}
          </Link>
        ) : (
          <>
            {isMobile && (
              <HeaderDropdown
                id={id}
                title={title}
                links={getDropdownLinks(dropDownOptions)}
                isDropdownOpen={isDropdownOpen}
                toggleDropdown={id => toggleDropdown(id)}
              />
            )}

            {!isMobile && (
              <>
                <div className='header-links-options-label'>{title}</div>
                <div className='header-links-options'>
                  {getDropdownLinks(dropDownOptions)}
                </div>
              </>
            )}
          </>
        )}
      </li>
    )
  )

  return <ul className='primary-links'>{headerLinks}</ul>
}

HeaderLinks.propTypes = {
  links: PropTypes.array,
  menuOpen: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  toggleDropdown: PropTypes.func
}

export default HeaderLinks
