import PT from 'prop-types'
import React from 'react'

import MainLayout from './layout'

const Layout = ({ children, ...rest }) =>
  rest.pageContext.noLayout ? (
    children
  ) : (
    <MainLayout {...rest}>{children}</MainLayout>
  )

Layout.propTypes = {
  children: PT.node
}

export default Layout
