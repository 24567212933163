import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const LinkImage = ({
  src,
  alt,
  imgClassName,
  href,
  linkClassName,
  tabIndex
}) => (
  <Link tabIndex={tabIndex} to={href} className={linkClassName}>
    <img src={src} alt={alt} className={imgClassName} />
  </Link>
)

LinkImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  imgClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  tabIndex: PropTypes.number
}

export default LinkImage
