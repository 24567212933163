import React from 'react'
import PropTypes from 'prop-types'

const HeaderDropdown = ({
  id,
  title,
  links,
  isDropdownOpen,
  toggleDropdown
}) => (
  <>
    <input
      id={id}
      type='checkbox'
      onChange={({ target }) => toggleDropdown(target.id)}
      checked={isDropdownOpen}
    />
    <label htmlFor={id} className='header-links-dropdown'>
      <span className='header-links-dropdown-label'>{title}</span>
      <div className='header-links-dropdown-options'>{links}</div>
    </label>
  </>
)

HeaderDropdown.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  links: PropTypes.array,
  isDropdownOpen: PropTypes.bool,
  toggleDropdown: PropTypes.func
}

export default HeaderDropdown
