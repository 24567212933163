// eslint-disable-next-line no-unused-vars
import React from 'react'

export const LANGUAGES = ['en', 'ru']

export const BLOG_TOKENS = {
  en: {
    TITLE: '',
    NEW: 'new',
    POPULAR: 'popular',
    ALL: 'all',
    TYPES: 'types',
    READ: 'read'
  },
  ru: {
    TITLE: '',
    NEW: 'новое',
    POPULAR: 'популярное',
    ALL: 'Все',
    TYPES: 'типы',
    READ: 'читать'
  }
}

export const HEADER_TOKENS = {
  en: [
    {
      id: '_home',
      title: 'Home',
      path: '/',
      tabIndex: 0,
      type: 'siteLink'
    },
    {
      id: '_solutions',
      title: 'Solutions',
      path: '/',
      tabIndex: 1,
      type: 'siteLink',
      dropDownOptions: [
        {
          title: 'White Label Online Casino Software',
          path: '/igaming-solutions-and-platforms/whitelabel-casino',
          type: 'siteLink'
        },
        {
          title: 'Turnkey Casino Software',
          path: '/igaming-solutions-and-platforms/turnkey-casino',
          type: 'siteLink'
        },
        {
          title: 'Self-Service Casino',
          path: '/igaming-solutions-and-platforms/self-service',
          type: 'siteLink'
        },
        {
          title: 'Bitcoin Casino Software',
          path: '/igaming-solutions-and-platforms/bitcoin-casino',
          type: 'siteLink'
        },
        {
          title: 'Casino Software Solutions',
          path: '/igaming-solutions-and-platforms',
          type: 'siteLink'
        }
      ]
    },
    {
      id: '_products',
      title: 'Products',
      path: '/',
      tabIndex: 2,
      type: 'siteLink',
      dropDownOptions: [
        {
          title: 'Casino Games Integration',
          path: '/products/casino-games-integration',
          type: 'siteLink'
        },
        {
          title: 'Slots Bundle',
          path: '/products/slots-bundle',
          type: 'siteLink'
        },
        {
          title: 'Live Casino Bundle',
          path: '/products/live-casino-bundle',
          type: 'siteLink'
        },
        {
          title: 'Sports Betting Software Providers',
          path: '/products/sports-betting-software-providers',
          type: 'siteLink'
        },
        {
          title: 'BonusSystem Standalone',
          path: '/products/bonus-system-standalone',
          type: 'siteLink'
        },
        { title: 'Banking', path: '/products/banking', type: 'siteLink' },
        {
          title: 'Online Casino Payment Solutions',
          path: '/products/online-casino-payment-solutions',
          type: 'siteLink'
        },
        { title: 'Licensing', path: '/products/licensing', type: 'siteLink' },
        {
          title: 'Cryptocurrency payment solution',
          path: '/products/cryptocurrency-payment-solution',
          type: 'siteLink'
        },
        { title: 'Hosting', path: '/products/hosting', type: 'siteLink' },
        {
          title: 'Promotion & SEO',
          path: '/products/promotion-seo',
          type: 'siteLink'
        },
        {
          title: 'Back office',
          path: '/products/back-office',
          type: 'siteLink'
        }
      ]
    },
    {
      id: '_games',
      title: 'Games',
      path: '/',
      tabIndex: 3,
      type: 'siteLink',
      dropDownOptions: [
        {
          title: 'Games catalog',
          path: '/games/games-catalog',
          type: 'siteLink'
        },
        {
          title: 'Game providers',
          path: '/games/games-providers',
          type: 'siteLink'
        }
      ]
    },
    {
      id: '_news&articles',
      title: 'News & Articles',
      path: '/',
      tabIndex: 4,
      type: 'siteLink'
    },
    {
      id: '_aboutus',
      title: 'About Us',
      path: '/',
      tabIndex: 5,
      type: 'siteLink',
      dropDownOptions: [
        { title: 'About Us', path: '/about-us/about-us', type: 'siteLink' },
        {
          title: 'Our clients',
          path: '/about-us/our-clients',
          type: 'siteLink'
        },
        { title: 'Advantages', path: '/abouts-us/advantages', type: 'siteLink' }
      ]
    },
    {
      id: '_contacts',
      title: 'Contacts',
      path: '/',
      tabIndex: 6,
      type: 'siteLink'
    }
  ],
  ru: [
    {
      id: '_home',
      title: 'Главная',
      path: '/',
      tabIndex: 0,
      type: 'siteLink'
    },
    {
      id: '_solutions',
      title: 'Решения',
      path: '/',
      tabIndex: 1,
      type: 'siteLink',
      dropDownOptions: [
        {
          title: 'Готовое интернет казино White Label',
          path: '/igaming-solutions-and-platforms/whitelabel-casino',
          type: 'siteLink'
        },
        {
          title: 'Онлайн казино под ключ',
          path: '/igaming-solutions-and-platforms/turnkey-casino',
          type: 'siteLink'
        },
        {
          title: 'Казино самостоятельной разработки',
          path: '/igaming-solutions-and-platforms/self-service',
          type: 'siteLink'
        },
        {
          title: 'Биткойн казино',
          path: '/igaming-solutions-and-platforms/bitcoin-casino',
          type: 'siteLink'
        },
        {
          title: 'Софт для онлайн казино',
          path: '/igaming-solutions-and-platforms',
          type: 'siteLink'
        }
      ]
    },
    {
      id: '_products',
      title: 'Продукты',
      path: '/',
      tabIndex: 2,
      type: 'siteLink',
      dropDownOptions: [
        {
          title: 'Интеграция игр для казино',
          path: '/products/casino-games-integration',
          type: 'siteLink'
        },
        {
          title: 'Slots Bundle',
          path: '/products/slots-bundle',
          type: 'siteLink'
        },
        {
          title: 'Live Casino Bundle',
          path: '/products/live-casino-bundle',
          type: 'siteLink'
        },
        {
          title: 'Решения для букмекерской конторы',
          path: '/products/sports-betting-software-providers',
          type: 'siteLink'
        },
        {
          title: 'Автономная бонусная система BonusSystem',
          path: '/products/bonus-system-standalone',
          type: 'siteLink'
        },
        {
          title: 'Банковские услуги',
          path: '/products/banking',
          type: 'siteLink'
        },
        {
          title: 'Игорная лицензия',
          path: '/products/licensing',
          type: 'siteLink'
        },
        {
          title: 'Криптовалюта - платежное решение',
          path: '/products/cryptocurrency-payment-solution',
          type: 'siteLink'
        },
        { title: 'Хостинг', path: '/products/hosting', type: 'siteLink' },
        {
          title: 'Продвижение и SEO',
          path: '/products/promotion-seo',
          type: 'siteLink'
        },
        { title: 'Бэк-офис', path: '/products/back-office', type: 'siteLink' }
      ]
    },
    {
      id: '_games',
      title: 'Игры',
      path: '/',
      tabIndex: 3,
      type: 'siteLink',
      dropDownOptions: [
        { title: 'Католог игр', path: '/games/games-catalog', type: 'siteLink' }
      ]
    },
    {
      id: '_news&articles',
      title: 'Блог',
      path: '/',
      tabIndex: 4,
      type: 'siteLink'
    },
    {
      id: '_aboutus',
      title: 'О нас',
      path: '/',
      tabIndex: 5,
      type: 'siteLink',
      dropDownOptions: [
        { title: 'О нас', path: '/about-us/about-us', type: 'siteLink' },
        {
          title: 'Наши клиенты',
          path: '/about-us/our-clients',
          type: 'siteLink'
        },
        {
          title: 'Преимущества',
          path: '/abouts-us/advantages',
          type: 'siteLink'
        }
      ]
    },
    {
      id: '_contacts',
      title: 'Контакты',
      path: '/',
      tabIndex: 6,
      type: 'siteLink'
    }
  ]
}

export const HEADER_LANGS = {
  en: 'EN',
  ru: 'RU'
}

export const FOOTER_TOKENS = {
  ABOUT: {
    en: 'О нас',
    ru: 'Про нас'
  },
  PRIVATE_PERSONS: {
    en: 'Частным лицам',
    ru: 'Приватним особам'
  },
  BUSINESS: {
    en: 'Бизнесу',
    ru: 'Бізнесу'
  },
  CONTACTS: {
    en: 'Контакты',
    ru: 'Контакти'
  },
  ADDRESS: {
    en: 'бульвар Дружбы народов, 28 В',
    ru: 'бульвар Дружби народів, 28 В'
  },
  ADDRESS_INDEX: {
    en: 'г. Киев 01103',
    ru: 'м. Київ 01103'
  }
}
