import { HEADER_TOKENS } from 'constants/Languages'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import LinkImage from '../Common/LinkImage/LinkImage'
import FooterMenu from './FooterMenu'

import './Footer.scss'

const Footer = ({ cult }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "logo.svg" }) {
            publicURL
          }
          pages: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  title
                  path
                  tags
                  lang
                }
              }
            }
          }
        }
      `}
      render={data => {
        let primaryLinks = [...HEADER_TOKENS[cult]]

        const options = primaryLinks.map(option => {
          let arr = []
          if (option.dropDownOptions) {
            for (let i = 0; i < option.dropDownOptions.length; i++) {
              arr.push(option.dropDownOptions[i])
            }
          }
          return arr
        })

        const generateMenuBlock = () => {
          let drops = []
          for (let i = 0; i < options.length; i++) {
            if (options[i].length !== 0) {
              drops.push(
                <FooterMenu
                  cult={cult}
                  links={options[i]}
                  title={primaryLinks[i].title}
                />
              )
            }
          }
          return drops
        }
        return (
          <footer className='footer-container'>
            <div className='footer'>
              <div className='footer-menus-wrapper'>{generateMenuBlock()}</div>
              <LinkImage
                src={data.logo.publicURL}
                href='/'
                alt='Alt text'
                imgClassName='footer-logo'
                linkClassName='footer-logo-container'
              />
              <span className='copyrights'>Copyrights &copy; 2016-2020</span>
            </div>
          </footer>
        )
      }}
    />
  )
}

Footer.propTypes = {
  cult: PropTypes.string
}

export default Footer
