import { NAMES } from '../actions/contactform'

const INITIAL_STATE = {
  name: '',
  email: '',
  code: '',
  phone: '',
  skype: '',
  message: '',
  success: false
}

export default function sentContact(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case NAMES.SET_FIELD_DATA:
      return {
        ...state,
        ...payload
      }
    case NAMES.SET_SUCCESS:
      return {
        ...state,
        success: payload
      }
    default:
      return state
  }
}
