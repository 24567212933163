import PropTypes from 'prop-types'
import React from 'react'

import './CloseIcon.scss'

const CloseIcon = ({ menuOpen }) => (
  <div className='close-icon-container'>
    <div onClick={menuOpen} className='close-icon' />
  </div>
)

CloseIcon.propTypes = {
  menuOpen: PropTypes.func
}

export default CloseIcon
